import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="grid-container narrow">
      <section className="typography text-center">
        <h1 class="text-shadow pink">Page Not Found</h1>
        <div className="typography">
          <b>Are you looking for:</b>
          <ul className="menu vertical">
            <li><Link to="/">Home</Link></li>
            <li><Link to="">See how you compare</Link></li>
            <li><Link to="">View the report summary</Link></li>
            <li><Link to="/about">About Melbourne Institute</Link></li>
            <li><Link to="/contact">Contact us</Link></li>
          </ul>
        </div>

      </section>
    </div>
  </Layout>
)

export default NotFoundPage
