import { Link } from "gatsby"
import React, { Component } from "react"

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggleClass = this.toggleClass.bind(this);
    this.state = {
      active: false
    }
  }

  toggleClass = () => {
    this.setState({ active: !this.state.active})
  }

  render() {
    return (
      <header>
        <nav className="nav grid-container-full">
          <div className="nav__icon">
            <button className={"hamburger " + (this.state.active ? 'change' : '')} onClick={this.toggleClass}>
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </button>
          </div>
          <div className={"nav__menu " + (!this.state.active ? "nav--hide" : '')} id="main-menu">
            <div className="grid-container">
              <ul className="menu vertical medium-horizontal">
                <li><Link to="/">Home</Link></li>
                {/* <li><Link to="">See how you compare</Link></li> */}
                <li><a href="https://melbourneinstitute.unimelb.edu.au/__data/assets/pdf_file/0011/3127664/HILDA-Statistical-Report-2019.pdf" target="_blank">View the statistical report</a></li>
                <li><Link to="/about">About Melbourne Institute</Link></li>
                <li><Link to="/contact">Contact us</Link></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
